const STARBASE_URL = process.env.NEXT_PUBLIC_STARBASE_URL

export const sendSms = async (phone) => {
  try {
    const isValid = !!phone?.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im)

    if (isValid) {
      await fetch(STARBASE_URL.concat('/send-download-link'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ phone })
      })
    }
  } catch (error) {
    console.error(error)
  }
}