import ApolloClient from 'apollo-boost'
import jsSHA from 'jssha'
import gql from 'graphql-tag'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { InMemoryCache } from 'apollo-cache-inmemory'
import _, {
  get,
  chain,
  isArray,
  values,
  merge,
  range,
  some,
  sortBy,
  omit,
} from 'lodash'
import { useEffect, useState } from 'react'
import { singular } from 'pluralize'
import { DateTime } from 'luxon'

import {
  storageMode,
  hasMultiServiceRequests,
  trackAvailabilitySearch,
  trackAvailabilitySearchResultFound,
  trackAvailabilitySearchResultNotFound,
  trackAvailabilitySearchResultNoAddonsFound,
  trackAvailabilitySearchAlternateTimeFound,
  trackAvailabilitySearchWithoutServiceFound,
  trackAvailabilitySearchAlternateServiceFound
} from '../helpers'
import { resolve } from 'bluebird'
import {
  PRODUCTS,
  ADDRESSES,
  PAYMENT_METHODS,
  ME,
  LOAD_USER,
  UPDATE_USER,
  CREATE_TRANSACTION,
  APPOINTMENT_DETAILS,
  APPOINTMENT_SERVICE_DETAILS,
  LOAD_MARKETS,
  SERVICE_TYPES,
  LOAD_SERVICES,
  LOAD_MARKET_SERVICES_WITH_ADD_ONS,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  DESTROY_ADDRESS,
  CREATE_PAYMENT_METHOD,
  DESTROY_PAYMENT_METHOD,
  LOAD_MEMBERSHIPS,
  LOAD_PACKAGES,
  LOAD_GIFT_CARD,
  LOAD_USER_APPOINTMENTS,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_SERVICE,
  DESTROY_APPOINTMENT_SERVICE,
  LOAD_CLIENT_SURVEY_QUESTIONS,
  CREATE_SURVEY_ANSWER,
  LOAD_APPOINTMENTS,
  FETCH_PRODUCT,
  GET_BANNER,
  UPDATE_MEMBERSHIP,
  UPDATE_SURVEY_ANSWER,
  CALCULATE_APPOINTMENT_PRICE
} from './GraphQlQueries'
import { LOAD_SERVICES_FOR_MARKET } from '.'
import moment from 'moment-timezone'

const STARBASE_URL = process.env.NEXT_PUBLIC_STARBASE_URL
const CLIENT_APPLICATION_ID = process.env.NEXT_PUBLIC_CLIENT_ID
const CLIENT_SECRET = process.env.NEXT_PUBLIC_CLIENT_SECRET

/**
 * retrieve the currently logged in user
 */
export const me = () =>
  getAccessToken() &&
  runQuery({
    query: ME,
  }).then(({ data: { me } }) => me)

/**
 * load user if available
 *
 * @param {*} [deps=[]]
 */
export const useMe = (deps = []) =>
  useStateEffect(me, undefined, [...deps, getAccessToken()])

export const signRequestByClient = async (method, endpoint, body) => {
  const sha = new jsSHA('SHA-256', 'TEXT')
  sha.setHMACKey(CLIENT_SECRET, 'TEXT')
  sha.update([method, endpoint, JSON.stringify(body)].join('|'))
  const hmac = sha.getHMAC('B64')
  
  return 'Glam ' + [CLIENT_APPLICATION_ID, hmac].join(':');
}

/**
 * buildAuthHeader: builds the authorization header for a request
 * 
 * uses accessToken if present, otherwise sign w/ client
 *
 * @param {*} accessToken
 * @param {*} path
 * @param {*} body
 * @return {*} 
 */
const buildAuthHeader = async (accessToken, path, body) => {
  if (accessToken) {
    return `Bearer ${accessToken}`
  }

  return signRequestByClient('POST', path, body)
}

/**
 * appointmentAvailabilitySearch: availability search
 *
 * @param {*} actualStartDateTime
 * @param {*} address
 * @param {*} serviceRequests
 * @returns
 */
export const appointmentAvailabilitySearch = async (
  dispatch,
  actualStartDateTime,
  timezone,
  timeVariance,
  timeInterval,
  address,
  serviceRequests,
  providerFilter,
  marketServices
) => {
  trackAvailabilitySearch(actualStartDateTime, serviceRequests, marketServices)

  // check if appointment is multiple services— if initial request fails,
  // we can query these separately
  const hasMultipleServices = hasMultiServiceRequests(serviceRequests)

  // check if appointment services have any addon services
  const hasAddonServices = some(serviceRequests, (request) => request.addOnServiceIds.length)

  dispatch({
    selectedAppointment: false
  })

  actualStartDateTime =
    DateTime.fromJSDate(actualStartDateTime).setZone(timezone)

  if (
    !(
      actualStartDateTime &&
      actualStartDateTime > new Date() &&
      actualStartDateTime.hour !== 0 &&
      address &&
      serviceRequests &&
      serviceRequests.length
    )
  ) {
    return null
  }

  // for simulataneous (not back-to-back) services, send an extra service request for each
  serviceRequests = serviceRequests.flatMap(sr => {
    if (sr.backToBack) {
      return sr
    }

    // include a duplicate service request for each instance requested
    return range(0, sr.instances).map(() => merge({}, sr, { instances: 1 }))
  })

  // build request body
  const body = {
    address,
    actualStartDateTime: actualStartDateTime.toSeconds(),
    services: serviceRequests,
    timeVariance,
    timeInterval,
    providerFilter
  }

  try {
    const path = '/api/v1/appointments/availability'

    // access token if we have on
    const accessToken = getAccessToken()

    const res = await fetch(
      STARBASE_URL.concat(path),
      {
        method: 'POST',
        headers: {
          Authorization: await buildAuthHeader(accessToken, path, body),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        timeout: 20000,
        responseType: 'json'
      }
    )

    // server error
    if (res.status > 499) {
      throw new Error('Error checking availability. Please try again.')
    }

    const responseBody = await res.json()
    const retry = {
      noAddonSearch: [],
      singleServiceSearch: [],
      alternateServiceSearch: {
        message: null,
        serviceRequests: [],
        alternateServiceId: null,
      },
    }

    // if we received an error, check if it's an alternateService error. otherwise rethrow
    if (res.status > 299) {
      const error = merge(new Error(), res, responseBody)

      // check if this is the error that we're looking for
      const alternateServiceError = error?.errors?.find(error => error.type === 'alternateService' && error.value)

      // rethrow error if not
      if (!alternateServiceError) {
        throw error
      }

      // replace the unavailable service with the alternate service
      const unavailableServiceId = alternateServiceError.path;
      const alternateServiceId = alternateServiceError.value;

      const alternateServiceRequests = serviceRequests.map(request => {
        if (request.serviceId === unavailableServiceId) {
          return { ...request, serviceId: alternateServiceId }
        }
        return request
      })

      // request again
      const alternateServiceBody = {
        ...body,
        services: alternateServiceRequests,
      }
      const alternateServiceResponse = await fetch(
        STARBASE_URL.concat(path),
        {
          method: 'POST',
          headers: {
            Authorization: await buildAuthHeader(accessToken, path, alternateServiceBody),
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(alternateServiceBody),
          timeout: 20000,
          responseType: 'json'
        }
      )

      // makes sure we got a 200 response
      if (alternateServiceResponse.status > 199 && alternateServiceResponse.status < 300) {
        const alternateResponseBody = await alternateServiceResponse.json()

        // return if we found some results
        if (alternateResponseBody.data.length) {
          retry.alternateServiceSearch = {
            ...retry.alternateServiceSearch,
            message: alternateServiceError.message,
            alternateServiceId,
            serviceRequests: alternateServiceRequests,
          }
          
          trackAvailabilitySearchAlternateServiceFound(actualStartDateTime, retry.alternateServiceSearch.serviceRequests, marketServices)

          // return retry with empty results
          return {
            data: [],
            retry,
          }
        }
      }
    }

    // if we got results, return
    if (responseBody.data.length) {
      const originalTimeSearch = actualStartDateTime.toSeconds()
      let isAlternateTime = false
      // check if result is an alternate time
      responseBody.data.forEach(result => {
        if (result.actualStartDateTime !== originalTimeSearch) {
          isAlternateTime = true
        }
      })

      if (isAlternateTime) {
        trackAvailabilitySearchAlternateTimeFound(actualStartDateTime, serviceRequests, marketServices)
      } else {
        trackAvailabilitySearchResultFound(actualStartDateTime, serviceRequests, marketServices)
      }

      return {
        data: responseBody.data,
        retry
      }
    }

    if (hasAddonServices) {
      body.services = serviceRequests.map(request => ({ ...request, addOnServiceIds: [] }))

      const res = await fetch(
        STARBASE_URL.concat(path),
        {
          method: 'POST',
          headers: {
            Authorization: await buildAuthHeader(accessToken, path, body),
            'Content-Type': 'application/json'
          },

          body: JSON.stringify(body),
          timeout: 20000,
          responseType: 'json'
        }
      )

      const data = await res.json()

      // if appointment is found for services w/o addons 
      // return original service requets without addon ids from body
      if (data.data.length) {
        retry.noAddonSearch = body.services
      }
    }

    if (hasMultipleServices) {
      // try services requests individually
      const singleServiceFetches = serviceRequests.map(s => ({
        address,
        actualStartDateTime: actualStartDateTime.toSeconds(),
        services: [{ ...s }],
        timeVariance,
        timeInterval,
        providerFilter
      }))

      const promises = singleServiceFetches.map(async s => fetch(
        STARBASE_URL.concat(path),
        {
          method: 'POST',
          headers: {
            Authorization: await buildAuthHeader(accessToken, path, s),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(s),
          timeout: 20000,
          responseType: 'json'
        }
      ))

      const singleServiceResults = (await Promise.all((await Promise.all(promises)).map(r => r.json()))).map(r => r.data)

      // filter original requests based on results
      const validServiceRequests = serviceRequests.filter(({ serviceId }) => (
        singleServiceResults.find((res) => res[0] && res[0].appointmentServices[0].serviceId === serviceId)
      ))

      // return retry array if partial availability
      // ignore if > 2 services are unavailable
      if (
        validServiceRequests.length &&
        validServiceRequests.length < serviceRequests.length &&
        (serviceRequests.length - validServiceRequests.length) < 3
      ) {
        retry.singleServiceSearch = validServiceRequests
      }
    }

    // no results found tracking
    if (!(responseBody.data.length && retry.noAddonSearch.length && retry.singleServiceSearch.length && retry.alternateServiceSearch.serviceRequests.length)) {
      trackAvailabilitySearchResultNotFound(actualStartDateTime, serviceRequests, marketServices)
    }

    // tracking for retry without addons
    if (!(responseBody.data.length) && retry.noAddonSearch.length) {
      trackAvailabilitySearchResultNoAddonsFound(actualStartDateTime, retry.noAddonSearch, marketServices)
    }

    if (!(responseBody.data.legnth) && retry.singleServiceSearch.length) {
      trackAvailabilitySearchWithoutServiceFound(actualStartDateTime, retry.singleServiceSearch, marketServices)
    }

    return {
      data: responseBody.data,
      retry
    }
  } catch (error) {
    formatAndRethrowError(error);
  }
}

/**
 * useAppointmentAvailabilitySearch
 *
 * @param {*} params
 */
export const useAppointmentAvailabilitySearch = (...params) =>
  useStateEffectWithError(
    () => appointmentAvailabilitySearch(...params),
    undefined,
    params.map(JSON.stringify)
  )

/**
 * bookAppointment
 *
 * TODO: this is not GraphQL. convert once we have a starbase endpoint
 *
 * @param {*} appointment
 * @param {*} appointmentAddress
 */
export const bookAppointment = async (appointment, appointmentAddress) => {
  appointment = { ...appointment } // make a copy since we're modifying it

  //TODO remove address from appointment entirely. not used anymore
  appointment.address = {}
  // access token if we have on
  const accessToken = getAccessToken()

  if (!appointment.ownerId) {
    appointment.ownerId = (await me()).id
  }

  const address = await updateOrCreateAddress(
    appointment.ownerId,
    appointmentAddress
  )
  appointment.addressId = address.id

  return fetch(
    STARBASE_URL.concat(`/api/v1/users/${appointment.ownerId}/appointments`),
    {
      method: 'POST',
      headers: {
        Authorization: accessToken ? 'Bearer '.concat(accessToken) : undefined,
        'Content-Type': 'application/json'
      },

      body: JSON.stringify(appointment),
      timeout: 15000,
      responseType: 'json'
    }
  ).then(res =>
    res
      .json()
      .then(({ data, errors }) => {
        if (errors && errors.length) {
          const err = new Error()
          err.errors = errors
          throw err
        }

        return data
      })
      .catch(formatAndRethrowError)
  )
}

/**
 * claimUserCredit
 *
 * @param {*} userCredit
 */
export const claimUserCredit = async (userId, claimCode) => {
  // access token if we have on
  const accessToken = getAccessToken()

  return fetch(
    STARBASE_URL.concat(`/api/v1/users/${userId}/user-credits/claim`),
    {
      method: 'POST',
      headers: {
        Authorization: accessToken ? 'Bearer '.concat(accessToken) : undefined,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ claimCode }),
      responseType: 'json'
    }
  )
    .then(res => {
      return res.json().then(body => {
        const { data } = body
        if (data) {
          return data
        }
        throw body
      })
    })
    .catch(formatAndRethrowError)
}

export const calcApptPrice = (startDateTime, address, appointmentServices) =>
  runQuery({
    query: CALCULATE_APPOINTMENT_PRICE,
    variables: { 
      startDateTime,
      address,
      appointmentServices
    },
    useCache: true
  }).then(({ data }) => data?.calculateAppointmentPrice?.appointment)

/**
 * updateOrCreateAddress
 *
 * @param {*} userId
 * @param {*} address
 * @returns
 */
export const updateOrCreateAddress = (userId, appointmentAddress) => {
  // TODO: optimize
  appointmentAddress.userId = userId

  if (appointmentAddress.id) {
    return getUserAddresses(userId)
      .filter(address => address.id === appointmentAddress.id)
      .then(([address]) => {
        if (address) {
          updateAddress(appointmentAddress.id, appointmentAddress)
          return appointmentAddress
        }
        return createAddress(appointmentAddress)
      })
  }
  return createAddress(appointmentAddress)
}

/**
 * createAddress
 *
 * @param {*} address
 */
export const createAddress = address =>
  runMutation({
    mutation: CREATE_ADDRESS,
    variables: {
      address: omit(address, ['__typename'])
    }
  }).then(({ data: { createAddress: address } }) => address)

/**
 * updateAddress
 *
 * @param {*} address
 */
export const updateAddress = (id, address) => {
  return runMutation({
    mutation: UPDATE_ADDRESS,
    variables: {
      id,
      address: omit(address, ['__typename'])
    }
  }).then(({ data: { updateAddress } }) => updateAddress)
}

export const destroyAddress = id =>
  runMutation({
    mutation: DESTROY_ADDRESS,
    variables: {
      id
    }
  }).then(({ data: { destroyAddress } }) => destroyAddress)

/**
 * createPaymentMethod
 *
 * @param {*} address
 */
export const createPaymentMethod = paymentMethod =>
  runMutation({
    mutation: CREATE_PAYMENT_METHOD,
    variables: {
      paymentMethod
    }
  }).then(({ data: { createPaymentMethod: paymentMethod } }) => paymentMethod)

/**
 * destroyPaymentMethod
 *
 * @param {string} id
 */
export const destroyPaymentMethod = id => {
  runMutation({
    mutation: DESTROY_PAYMENT_METHOD,
    variables: {
      id
    }
  }).then(({ data: { destroyPaymentMethod } }) => destroyPaymentMethod)
}

/**
 * fetches a user
 *
 * @param {*} userId
 */
export const findUserById = userId =>
  runQuery({
    query: LOAD_USER,
    variables: { where: { id: userId } },
    useCache: true
  }).then(({ data }) => data.users[0])

/**
 * fetches appointments for an authenticated user
 *
 *
 */
export const queryUserAppointments = () =>
  runQuery({
    query: LOAD_USER_APPOINTMENTS,
    useCache: true
  }).then(({ data }) => data.me.appointments)

export const useMeAppointments = () =>
  useStateEffect(queryUserAppointments, undefined, [getAccessToken()])

export const updateAppointment = (id, newInfo) =>
  runMutation({
    mutation: UPDATE_APPOINTMENT,
    variables: {
      id,
      appointment: newInfo
    }
  }).then(({ data }) => data.updateAppointment)

export const updateAppointmentService = (id, newInfo) =>
  runMutation({
    mutation: UPDATE_APPOINTMENT_SERVICE,
    variables: {
      id,
      appointmentService: newInfo
    }
  }).then(({ data }) => data.updateAppointmentService)

export const destroyAppointmentService = id =>
  runMutation({
    mutation: DESTROY_APPOINTMENT_SERVICE,
    variables: {
      id
    }
  }).then(
    ({ data: { destroyAppointmentService } }) => destroyAppointmentService
  )

/**
 * 
 * @param {*} input SurveyAnswerInput
 * @returns 
 */
export const createSurveyAnswer = surveyAnswer =>
  runMutation({
    mutation: CREATE_SURVEY_ANSWER,
    variables: { surveyAnswer }
  }).then(({ data: { createSurveyAnswer } }) => createSurveyAnswer)

export const updateSurveyAnswer = surveyAnswer =>
  runMutation({
    mutation: UPDATE_SURVEY_ANSWER,
    variables: { id: surveyAnswer.id, surveyAnswer }
  }).then(({ data: { updateSurveyAnswer } }) => updateSurveyAnswer)

/**
 * saves and returns updated user
 *
 * @param {*} user
 * @returns
 */
export const updateUser = user =>
  runMutation({
    mutation: UPDATE_USER,
    variables: {
      id: user.id,
      user
    }
  }).then(({ data: { updateUser } }) => updateUser)

/**
 * runSubscriptionWatchedQuery: calls runQuery with args provided and subscribes to changes
 * in the type of resource returned and updates cache with any changed values
 *
 * NOTE: this does not subscribe to changes w/ a filter currently so it will receive EVERY
 * event for this type. use w/ caution
 *
 * @param {*} runQueryArgs
 * @returns
 */
const runSubscriptionWatchedQuery = runQueryArgs => {
  // we want to cache and use a subscription to update cache
  runQueryArgs.useCache = true

  return resolve(runQuery(runQueryArgs)).then(result => {
    (() => {
      const { query } = runQueryArgs

      // check if we're already watching this query
      const stringifiedQuery = JSON.stringify(query)
      if (watchedQueries[stringifiedQuery]) {
        return
      }
      watchedQueries[stringifiedQuery] = true

      // build the subcription query based on the original query
      const { root, fields, fragments } = getRootFieldsAndFragments(query)

      // construct event name to subscribe to from root selection
      const singularName = singular(root)
      const eventName = singularName + 'Event'

      // build base subscribe query
      const subscriptionQuery = gql`
          subscription {
            ${eventName} {
              ${singularName} {
                __typename
              }
            }
          }
        `

      // add fields from original query
      findNodeByName(
        subscriptionQuery,
        singularName
      ).selectionSet.selections.push(...fields)

      // add fragments if the query used any
      subscriptionQuery.definitions.push(...fragments)

      // updating the cache requires a typename for every type nested anywhere the query
      // so request the typename metafield for each
      const typenameField = {
        kind: 'Field',
        name: {
          kind: 'Name',
          value: '__typename'
        },
        arguments: [],
        directives: []
      }

      // find all selection lists in the doc and add typename
      callOnPropertiesWithName(fields, 'selections', node =>
        node.push(typenameField)
      )

      // NOTE: subscriptions disabled

      // subscribe
      // subscribe({
      //   query: subscriptionQuery,
      //   handler: ({ data }) => {
      //     const item = get(data, [eventName, singularName])

      //     // update cache w/ new/changed item received
      //     updateCache(query, item)
      //   }
      // })
    })()

    return result
  })
}

// queries we're currently watching w/ subscription
const watchedQueries = {}

/**
 * return names of root and fields and fragment definitions for gql query
 *
 * @param {*} document
 * @returns
 */
const getRootFieldsAndFragments = document => {
  const root = get(document, 'definitions.0.selectionSet.selections.0')

  return {
    root: get(root, 'name.value'),
    fields: get(root, 'selectionSet.selections'),
    fragments: document.definitions.filter(
      def => def.kind === 'FragmentDefinition'
    )
  }
}

/**
 * search gql query tree for attributes with a given name and call fn
 * passing the value. used to add __typename field to each query in a
 * subscription
 *
 * @param {*} node
 * @param {*} name
 * @param {*} fn
 * @returns
 */
const callOnPropertiesWithName = (node, name, fn) => {
  if (!node) {
    return
  }
  if (node[name]) {
    fn(node[name])
  }

  return values(node)
    .filter(o => typeof o === 'object')
    .flatMap(o => (isArray(o) ? o : [o]))
    .map(o => callOnPropertiesWithName(o, name, fn))
}

/**
 * finds a named node in a gql document
 *
 * @param {*} node
 * @param {*} name
 * @returns
 */
const findNodeByName = (node, name) => {
  if (get(node, 'name.value') === name) {
    return node
  }

  return values(node)
    .filter(o => typeof o === 'object')
    .flatMap(o => (isArray(o) ? o : [o]))
    .map(o => findNodeByName(o, name))
    .filter(o => !!o)[0]
}

/**
 * cachedLoadAndSubscribeFunctionsForQuery - returns functions which use provided query for loading
 * all of a resource, watch for changes via subscription and invalidate cache
 *
 * NOTE: listens for all changes to the type of resource requested and will receive EVERY
 * change event for that type
 *
 * @param {*} loadAllQuery
 *
 * @returns [
 *  loadAll,
 *  fetchOne,
 *  useAll,
 *  useOne,
 * ]
 */
const cachedLoadAndSubscribeFunctionsForQuery = (
  loadAllQuery,
  loadAllVariables
) => {
  const { root } = getRootFieldsAndFragments(loadAllQuery)

  // runs the provided query and watches for changes to the resource requested
  const loadAll = () =>
    runSubscriptionWatchedQuery({
      query: loadAllQuery,
      variables: loadAllVariables
    }).then(({ data }) => data[root])

  // function to fetch specific instance. just calls loadAll
  const fetchOne = id =>
    loadAll().then(results => results.find(o => o.id === id))

  const useAll = (deps = []) => useStateEffect(() => loadAll(), undefined, deps)
  const useOne = (id, deps = []) =>
    useStateEffect(() => fetchOne(id), undefined, [id, ...deps])

  return [loadAll, fetchOne, useAll, useOne]
}

/**
 * runs provided effect and stores result in state. returns the value of the state.
 * throws any errors returned
 *
 * @param {*} effect - passed to useEffect
 * @param {*} initialState - passed to useState
 * @param {*} [deps=[]] - passed to useEffect
 * @returns value
 */
const useStateEffect = (effect, initialValue, deps = []) => {
  // call the version that catches and returns errors
  const [value, error] = useStateEffectWithError(effect, initialValue, deps)
  // throw any error returned
  if (error) {
    throw Error(error)
  }

  return value
}

/**
 * runs provided effect and stores result in state.
 *
 * returns the value and error of the state
 *
 * @param {*} effect - passed to useEffect
 * @param {*} initialState - passed to useState
 * @param {*} [deps=[]] - passed to useEffect
 * @returns [value, error]
 */
const useStateEffectWithError = (effect, initialValue, deps = []) => {
  if (!effect) {
    throw new Error('useStateEffect: missing param')
  }

  const [{ value, error, retry }, setState] = useState({
    value: initialValue,
    error: undefined,
    retry: null
  })

  const setStateValue = value => {
    if (value && value?.retry) {
      setState({ value: value.data, error: undefined, retry: value.retry })
    } else {
      setState({ value, error: undefined })
    }
  }
  const setStateError = error => setState({ value: undefined, error })

  useEffect(
    () => {
      setState({ value: undefined, error: undefined }) // reset existing state

      resolve(effect()).then(setStateValue).catch(setStateError)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

  if (retry) {
    return [value, error, retry]
  }

  return [value, error]
}

/**
 * Markets
 */
export const [loadMarkets, fetchMarket, useAllMarkets, useMarket] =
  cachedLoadAndSubscribeFunctionsForQuery(LOAD_MARKETS)

/**
 * Markets
 */
export const [
  loadMarketServices,
  fetchMarketServices,
  useAllMarketServices,
  useMarketService
] = cachedLoadAndSubscribeFunctionsForQuery(LOAD_MARKET_SERVICES_WITH_ADD_ONS, {
  marketServicesWhere: {
    isAddOn: false,
    isHidden: false,
    isClientBookable: true
  },
  addOnMarketServicesWhere: { isPureAddOn: true }
})

/**
 * ServiceTypes
 */
export const [
  loadServiceTypes,
  fetchServiceType,
  useAllServiceTypes,
  useServiceType
] = cachedLoadAndSubscribeFunctionsForQuery(SERVICE_TYPES)

/**
 * Services
 */
export const [loadServices, fetchService, useAllServices, useService] =
  cachedLoadAndSubscribeFunctionsForQuery(LOAD_SERVICES)

/**
 * Products
 */
export const [loadProducts, fetchProduct, useAllProducts, useProduct] =
  cachedLoadAndSubscribeFunctionsForQuery(PRODUCTS)

/**
 * Memberships
 */
export const [
  loadMemberships,
  fetchMembership,
  useAllMemberships,
  useMembership
] = cachedLoadAndSubscribeFunctionsForQuery(LOAD_MEMBERSHIPS)

/**
 * retrieve a user's addresses
 *
 * @param {*} userId
 */
export const getUserAddresses = userId =>
  runQuery({
    query: ADDRESSES,
    variables: { where: { userId } }
  }).then(({ data: { addresses } }) => addresses)

/**
 * retrieve a user's payment methods
 *
 * @param {*} userId
 */
export const getUserPaymentMethods = userId =>
  createClient()
    .query({
      query: PAYMENT_METHODS,
      variables: { where: { userId: userId } }
    })
    .then(({ data: { paymentMethods } }) => paymentMethods)

/**
 * checkout an order
 *
 * @param {*} order
 */
export const checkoutOrder = orderInfo => {
  return runMutation({
    mutation: CREATE_TRANSACTION,
    variables: {
      transaction: orderInfo
    }
  }).then(
    ({
      data: {
        createTransaction: { id }
      }
    }) => id
  ).catch(formatAndRethrowError)
}

/**
 * fetch a specific appointment along with owner, appointment service,
 * address and location information
 *
 * @param {*} id
 */
export const fetchAppointment = id =>
  runQuery({
    query: APPOINTMENT_DETAILS,
    variables: {
      appointmentId: id
    }
  }).then(({ data: { appointment } }) => appointment)

/**
 * fetch a specific appointment along with owner, appointment service,
 * address and location information
 *
 * @param {*} id
 */
export const fetchAppointmentService = id =>
  runQuery({
    query: APPOINTMENT_SERVICE_DETAILS,
    variables: {
      id
    }
  }).then(({ data: { appointment } }) => appointment)

/**
 * loads packages for a specific market id
 *
 *
 * @param {*} marketId
 */
export const fetchMarketPackages = marketId =>
  runQuery({
    query: LOAD_PACKAGES,
    variables: {
      marketId
    }
  }).then(({ data: { market } }) =>
    sortBy(market.products.filter(product => product.productGroup?.isEnabled), 'productGroup.priority')
  )

/**
 * loads services for a specific market id
 * weddings not included for services page
 *
 * @param {*} marketId
 */

export const fetchServicesForMarket = marketId =>
  runQuery({
    query: LOAD_SERVICES_FOR_MARKET,
    variables: {
      id: marketId
    }
  }).then(
    ({
      data: {
        market: { marketServices }
      }
    }) => {
      return _(marketServices)
        .sortBy(s => s.service.priority)
        .map(s => {
          s.addOnMarketServices = sortBy(s.addOnMarketServices, 'service.priority')
          return s
        })
        .groupBy(s => s.service.serviceType.name)
        .value()
    }
  )

export const fetchGiftCard = () =>
  runQuery({
    query: LOAD_GIFT_CARD
  }).then(({ data: { products } }) => products[0])

/**
 * 
 * fetch question groups and questions for client post-appointment survey
 * @returns 
 */

export const fetchClientSurveyQuestions = () =>
  runQuery({
    query: LOAD_CLIENT_SURVEY_QUESTIONS,
  }).then(({
    data: {
      surveyQuestionGroups
    }
  }) => surveyQuestionGroups)
    .filter(g => g.by === 'USER')

/**
 * react hook which runs a query and calls handler with results
 *
 * @param {*} query
 * @param {*} variables
 * @param {*} handler
 * @param {*} [values=[]]
 * @returns
 */
export const useQuery = (query, variables, handler, values = []) =>
  useEffect(() => {
    runQuery({ query, variables }).then(({ data }) => handler(data))
  }, [query, variables, handler, values])

/**
 * react hook which subscribes with a query and calls handler as events arrive
 *
 * @param {*} query
 * @param {*} variables
 * @param {*} handler
 * @param {*} [values=[]]
 * @returns
 */
export const useSubscription = (query, filter, handler, values = []) =>
  useEffect(
    () => subscribe({ query, variables: { filter }, handler }).close,
    [query, filter, handler, values]
  )

/**
 * runs a query
 *
 * @param {*} {
 *   query,
 *   variables = {},
 *   useCache = false,
 *   requestId,
 * }
 * @returns Promise
 */
export const runQuery = ({
  query,
  variables = {},
  useCache = false,
  requestId
}) => {
  const client = createClient()
  return resolve()
    .then(() => client.query({
      query,
      variables,
      fetchPolicy: useCache ? 'cache-first' : 'network-only'
    }))
    .then(res => {
      const errors = res.data.errors
      if (errors && errors.length) {
        throw new Error(errors.map(e => e.message))
      }

      const data = res.data

      return { data, requestId }
    })
    .catch(formatAndRethrowError)
}

/**
 * runs a mutation
 *
 * @param {*} {
 *   mutation,
 *   variables = {},
 *   requestId,
 * }
 * @returns Promise
 */
export const runMutation = ({ mutation, variables = {}, requestId }) => {
  const client = createClient()

  return resolve()
    .then(() => client.mutate({
      mutation,
      variables
    }))
    .then(({ data }) => {
      const errors = data.errors
      if (errors && errors.length) {
        throw new Error(errors.map(e => e.message))
      }

      return { data, requestId }
    })
    .catch(err => {
      formatAndRethrowError(err)
    })
}

// the subscription client
let subscriptionClient

/**
 * subscribe to a query with a handler
 *
 * @param {*} { query, variables, handler }
 * @returns
 */
export const subscribe = async ({ query, variables, handler }) => {
  const accessToken = getAccessToken()

  // connect if not already connected
  if (!subscriptionClient) {
    const protocol = window.location.protocol === 'http:' ? 'ws://' : 'wss://'

    const subscriptionEndpoint =
      STARBASE_URL.replace(/^https?:\/\//i, protocol) + '/api/v1/graphql'

    // const subscriptionEndpoint = window.location.protocol === 'http:'
    //   ? `ws://${STARBASE_URL}/api/v1/graphql`
    //   : `wss://${STARBASE_URL}/api/v1/graphql`

    subscriptionClient = new SubscriptionClient(subscriptionEndpoint, {
      reconnect: true,
      connectionParams: { authorization: accessToken }
    })

    // reconnect periodically
    setInterval(() => subscriptionClient.close(false), 60 * 1000)
  }

  // request and subscribe w/ handler
  const { unsubscribe } = subscriptionClient
    .request({ query, variables })
    .subscribe({ next: handler })

  return {
    // return function to unsubscribe
    close: unsubscribe
  }
}

// apollo client and cache
let client

const cache = new InMemoryCache()

// last access token. used to clear cache when changed
let previousAccessToken

/**
 * create an apollo client w/ caching for queries & mutations
 *
 * @returns
 */
const createClient = () => {
  const accessToken = getAccessToken()

  // return same client unless token has changed
  if (!client) {
    previousAccessToken = accessToken

    const headers = {}
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`
    }

    client = new ApolloClient({
      uri: STARBASE_URL.concat('/api/v1/graphql'),
      cache,
      request: async operation => {
        operation.setContext({
          headers
        })
      }
    })
  }

  return client
}

/**
 * clears the apollo cache
 *
 */
const clearCache = () => {
  cache.data.clear()
}

/**
 * retrieve the current access token from session/local storage
 *
 * @returns
 */
export const getAccessToken = () => {
  const localstorage = storageMode('localStorage')
  const sessionstorage = storageMode('sessionStorage')
  const accessToken =
    localstorage.getItem('accessToken') || sessionstorage.getItem('accessToken')
  if (accessToken !== previousAccessToken) {
    // token changed, clear the cache
    clearCache()
    client = null
  }
  previousAccessToken = accessToken

  return accessToken
}

/**
 * attempt to format server errors received in various forms
 *
 * @param {*} err
 */
export const formatAndRethrowError = err => {
  console.dir(err)
  const errors = chain([])
    .concat(get(err, 'graphQLErrors', []).map(err => err.message))
    .concat(get(err, 'errors', []).map(err => err.message))
    .concat(get(err, 'networkError.result.errors', []).map(err => err.message))
    .concat(get(err, 'message'))
    .compact() // removes falsey values from array
    .map(msg => msg.replace('GraphQL error: ', ''))
    .uniq()
    .valueOf()
    .join('. ')
    .replace(/GraphQL error:\s/g, '')
    .replace('null: ', '')
    .trim()

  if (errors && errors.length) {
    err.message = errors
  }

  throw err
}

/**
 * locateMarketForAddress: returns the market associated with provided address, if any
 *
 * @param {*} address
 * @returns
 */
export const locateMarketForAddress = address => {
  const accessToken = getAccessToken()

  return fetch(STARBASE_URL.concat('/api/v1/markets/address-lookup'), {
    method: 'POST',
    headers: {
      Authorization: accessToken ? 'Bearer '.concat(accessToken) : undefined,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(address),
    timeout: 15000,
    responseType: 'json'
  })
    .then(res => res.json())
    .then(({ data }) => data)
    .catch(formatAndRethrowError)
}


export const fetchAppointments = (userId, limit, offset) => {

  const variables = {
    where: {
      ownerId: userId,
    },
    limit,
    offset
  }

  runQuery({
    query: LOAD_APPOINTMENTS,
    variables
  }).then(({ data }) => data)
    .catch(e => formatAndRethrowError(e))
}

export const fetchUpcomingAppointments = (userId) => {

  const variables = {
    where: {
      ownerId: userId,
      actualStartDateTime: { gte: moment().toISOString() },
      isCanceled: false
    },
    limit: 200,
    offset: 0,
    order: 'actualStartDateTime'
  }

  return runQuery({
    query: LOAD_APPOINTMENTS,
    variables
  }).then(({ data: { appointments } }) => appointments)
    .catch(e => console.log(e))
}

export const fetchPastAppointments = (userId, limit, offset) => {
  const variables = {
    where: {
      ownerId: userId,
      actualStartDateTime: { lt: moment().toISOString() },
      isCanceled: false
    },
    limit,
    offset,
    order: 'reverse:actualStartDateTime'
  }

  return runQuery({
    query: LOAD_APPOINTMENTS,
    variables
  }).then(({ data: { appointments } }) => appointments)
    .catch(e => console.log(e))
}

export const fetchSingleProduct = (id) =>
  runQuery({
    query: FETCH_PRODUCT,
    variables: { id }
  }).then(({ data: { product } }) => product)
    .catch(formatAndRethrowError)

export const getBanner = async () => {
  try {
    const { data: { getBanner } } = await runQuery({
      query: GET_BANNER,
      variables: {
        clientApplicationId: CLIENT_APPLICATION_ID
      }
    })
    return getBanner
  } catch (e) {
    formatAndRethrowError(e)
  }
}

export const updateMembership = (id, membership) => {
  return runMutation({
    mutation: UPDATE_MEMBERSHIP,
    variables: {
      id,
      membership
    }
  }).then(({ data: { updateMembership } }) => updateMembership)
    .catch(formatAndRethrowError)
}
