/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react'
import { FooterContainer, FooterLinkSection } from './styles'
import { Header2, Header3, Text, Button } from '../../styles/Components'
import Link from 'next/link'
import { serviceUrls, supportUrls, aboutUrls } from './url-data'
import { InstagramIcon, FacebookIcon, TikTokIcon, LinkedInIcon } from './social-icons'
import { useRouter } from 'next/router'
import styled from '@emotion/styled'
import { sendSms } from '../../services/sms'
import PhoneNumberInput from '../phone-number-input'
import Image from 'next/image'
import { mq } from '../../helpers'

const PhoneInputContainer = styled.div`
  max-width: 500px;

  ${mq('max', '768px')} {
    display: none;
  }
`

const AppStoreContainer = styled.div`
  position: relative;
  height: 46px;
  margin-top: 30px;
`

const Footer = ({ forceShow = false }) => {
  const [smsInput, setSMSInput] = useState('')
  const [working, setWorking] = useState(false)
  const [smsSent, setSmsSent] = useState(false)

  const isValidPhone = !!smsInput?.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im)

  const handlePhoneInput = (input) => {
    setSMSInput(input)
  }

  const handleSendSMS = async () => {
    try {
      setWorking(true)
      await sendSms(smsInput)
      setSmsSent(true)
    } catch (error) {
      console.error(error)
    } finally {
      setWorking(false)
    }
  }

  const appUrl = 'https://apps.apple.com/us/app/glamsquad/id766676007?ign-mpt=uo%3D4'
  const socialLinks = {
    instagram: 'https://www.instagram.com/glamsquad/',
    facebook: 'https://www.facebook.com/GlamSquad/',
    tiktok : 'https://www.tiktok.com/@glamsquad',
    linkedin: 'https://www.linkedin.com/company/glamsquad'
  }

  const { pathname } = useRouter()

  if (
    (!forceShow && pathname.includes('book')) ||
    pathname.includes('printable') ||
    pathname.includes('appointment-survey')
  ) {
    return null
  }

  return (
    <FooterContainer>
      <div className='footer-top'>
        <div className='app-section'>
          <div className='left-header'>
            <Header2>Book your glam on the go</Header2>
            <Text variant='medium'>Download the Glamsquad app</Text>
          </div>

          <PhoneInputContainer>
            <PhoneNumberInput
              autoComplete='tel'
              value={smsInput}
              onChange={handlePhoneInput}
              state={{ value: smsInput || '' }}
            />
            <Text margin='12px 0' fontSize={12}>
              By continuing you consent to receive text messages from Glamsquad. Message & data rates may apply.
            </Text>
            <Button
              onClick={handleSendSMS}
              fill
              large
              color='#000'
              disabled={!isValidPhone || working || smsSent}
            >
              { smsSent ? 'Link Sent' : 'Send Me a Link'}
            </Button>
          </PhoneInputContainer>

          <AppStoreContainer>
            <a href={appUrl}>
              <Image
                src='/images/download_on_app_store.svg'
                alt='app-store-download' 
                width='130'
                height='46'
              />
            </a>
          </AppStoreContainer>
        </div>

        <div className='footer-right'>
          <div className='link-sections'>
            <div className='sections-container'>
              <FooterSection 
                title='Shop'
                sections={serviceUrls}
              />
              <FooterSection 
                title='About'
                sections={aboutUrls}
              />
              <FooterSection 
                title='Support'
                sections={supportUrls}
              />
            </div>
          </div>
          <div className='footer-social'>
            <div><a href={socialLinks.instagram}><InstagramIcon/></a></div>
            <div><a href={socialLinks.facebook}><FacebookIcon /></a></div>
            <div><a href={socialLinks.tiktok}><TikTokIcon /></a></div>
            <div><a href={socialLinks.linkedin}><LinkedInIcon /></a></div>
          </div>
        </div>
      </div>

      <div className='footer-bottom-logo'>
        <img src='/images/new-gs-wordmark-logo.svg' width='358' height='49.219' alt='glamsquad logo' />
      </div>
    </FooterContainer>
  )
}

const FooterSection = ({ title, sections }) => {
  const [ open, setOpen ] = useState(false)

  const toggle = () => {
    setOpen(s => !s)
  }

  return (
    <FooterLinkSection>
      <div onClick={toggle} className='section-header-wrapper'>
        <Header3>{title}</Header3>
        <button style={{ color: '#000' }}>{!open ? '+' : '-'}</button>
      </div>

      <ul className={`${open ? '' : 'hidden'}`}>
        { sections.map(section => (
          <li key={section.name}>
            { section.link && (
              <Link href={section.link}><Text>{section.name}</Text></Link>
            )}

            { section.href && (
              <Link legacyBehavior href={section.href}><a target='_blank'><Text>{section.name}</Text></a></Link>
            )}
          </li>
        ))}
      </ul>
    </FooterLinkSection>
  )
}

export default Footer
